import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
// import { format } from "mysql";
// import 'react-datepicker/dist/DatePicker.css';
// import 'react-calendar/dist/Calendar.css';
// import { useState } from 'react';


class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: "",
        adminTypeToDel: "",
        numOFFDays: "0",
        branchesList: "",
        allBranchesList: "",
        itemsCats: []
    }
    setupSalesReportsTable() {
        const self = this;
        function getMyDate(date) {
            if(!date) {
                return "";
            }
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        window.$('#salesReportsTable').DataTable({
            'columnDefs': [
                { className: 'dt-left', targets: 1 },
                { "max-width": "250px", "width": "250px", "targets": 1 },
            ],
            order: [[2, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            // bLengthChange: false,
            // paging: false,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = [];
                const cat = $("#selectedCategory").val();
                const branch = $("#selectedBranch").val();
                const date = $("#selectedDate").val();
                var fromDate = "1900-01-01";
                var toDate = "9999-01-01";
                if(date === "custom" && $("#fromDatePicketId").val() && $("#toDatePicketId").val()) {
                    fromDate = $("#fromDatePicketId").val();
                    toDate = $("#toDatePicketId").val();
                }
                // const fromDate = $("#fromDatePicketId").val() ? $("#fromDatePicketId").val() : "1900-01-01";
                // const toDate = $("#toDatePicketId").val() ? $("#toDatePicketId").val() : "9999-01-01";
                await axios.post('/sales_reports_table', {fromDate:fromDate, toDate:toDate, date:date, cat:cat, branch:branch, offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                    $("#totalSalesCountSet").html(ok.data[2]);
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const itemName = resData[vCount].item_name;
                    const itemCost = resData[vCount].item_cost;
                    const itemPrice = resData[vCount].item_price;
                    const itemDiscount = resData[vCount].item_discount;
                    const itemFinalPrice = resData[vCount].final_price;
                    const itemQty = resData[vCount].item_qty;
                    const itemQtyAfter = resData[vCount].item_qty_after;
                    const itemTotalPrice = resData[vCount].total_price;
                    const itemProfit = resData[vCount].profit;
                    const itemDate = getMyDate(resData[vCount].date);
                    const itemStatus = resData[vCount].status;
                    const itemBranch = resData[vCount].branch;
                    const itemCustomer = resData[vCount].customer;
                    const itemCategory = resData[vCount].category;
                    const itemInvoiceId = resData[vCount].invoice_id;

                    out.push([id, itemName, itemQty, itemQtyAfter, itemBranch, itemCost, itemPrice, itemDiscount, itemFinalPrice, itemTotalPrice, itemProfit, itemDate, itemStatus, itemCustomer, itemCategory, itemInvoiceId]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#logout_staff");
        $(document).on("click", "#logout_staff", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminIdToDel:itemId, adminToDel:itemName});
            window.$("#deleteAdminModal").modal("show");
        });
    }
    setupStockReportsTable() {
        const self = this;
        function getMyDate(date) {
            if(!date) {
                return "";
            }
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        window.$('#stockReportsTable').DataTable({
            'columnDefs': [{
            'targets': [2],
            'render': function(data, type, full, meta) {
                        if(type === 'display'){ data = strtrunc(data, 10); } 
                            return data; 
                      }
            }],
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = [];
                const branch = $("#selectedBranch").val();
                const cat = $("#selectedCategory").val();
                await axios.post('/stock_reports_table', {cat:cat, branch:branch, offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                    $("#stockCashSetInput").html(parseFloat(ok.data[2]).toLocaleString("en", {useGrouping:true}));
                    $("#stockCountSetInput").html(parseFloat(ok.data[3]).toLocaleString("en", {useGrouping:true}));
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const name = resData[vCount].name;
                    const qty = resData[vCount].qty;
                    const warningQty = resData[vCount].warn_qty;
                    const branch = resData[vCount].branch;
                    const cat = resData[vCount].category;
                    const cost = resData[vCount].cost;
                    const totalCost = resData[vCount].total_cost
                    
                    out.push([id, name, qty, warningQty, branch, cat, cost, totalCost]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#logout_staff");
        $(document).on("click", "#logout_staff", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminIdToDel:itemId, adminToDel:itemName});
            window.$("#deleteAdminModal").modal("show");
        });
    }
    setupMonthSellsByDayTable = () => {
        const self = this;
        function getMyDate(date) {
            if(!date) {
                return "";
            }
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        window.$('#monthSellsByDayTable').DataTable({
            'columnDefs': [{
            'targets': [2],
            'render': function(data, type, full, meta) {
                        if(type === 'display'){ data = strtrunc(data, 10); } 
                            return data; 
                      }
            }],
            dom: 'lBfrtip',
            buttons: [
                {
                    extend: 'pdfHtml5',
                    text: 'Pdf',
                    filename: 'dt_custom_pdf',
                    pageSize: 'A4',
                    exportOptions: {
                        columns: [0, 1, 2, 3, 5]
                    },
                    customize: function ( doc ) {
                        doc.content[1].table.widths =Array(doc.content[1].table.body[0].length + 1).join('*').split('');
                        doc.defaultStyle.alignment = 'center';
                        doc.styles.tableHeader.alignment = 'center';
                    }
                }, "excel", "copy"
            ],
            
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = [];
                const branch = $("#selectedBranch").val();
                const date = $("#selectedDate2").val();
                const discountVal = $("#discountValueReportsId").val();
                await axios.post('/monthly_sales_by_day', {dis:discountVal, date:date, branch:branch, offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                    // $("#stockCashSetInput").html(parseFloat(ok.data[2]).toLocaleString("en", {useGrouping:true}));
                    // $("#stockCountSetInput").html(parseFloat(ok.data[3]).toLocaleString("en", {useGrouping:true}));
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].itemId;
                    const dayName = resData[vCount].dayName;
                    const date = resData[vCount].date;
                    const totalSalesValue = resData[vCount].totalSalesValue;
                    const branch = resData[vCount].branch;
                    const totalProfitValue = resData[vCount].totalProfitValue;

                    out.push([String(id), date, dayName, totalSalesValue, totalProfitValue, branch, ""]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#logout_staff");
        $(document).on("click", "#logout_staff", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminIdToDel:itemId, adminToDel:itemName});
            window.$("#deleteAdminModal").modal("show");
        });
    }
    componentDidMount() {
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            if(!ok.data.access.includes("|45|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#reports_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            // $(".MuiButtonBase-root").css({backgroundColor: ""});
            // $("#reports_btn").css({backgroundColor: "cadetblue"});
            this.setupBranches();
            this.setupCats();
            this.setupStockReportsTable();
            this.setupSalesReportsTable();
            this.setupMonthSellsByDayTable();
            $("#stockReportsTable_wrapper").hide();
            $("#salesReportsTable_wrapper").hide();
            $("#monthSellsByDayTable_wrapper").hide();
            // this.setupDatatable();
            // this.setupEmployees();
            // this.setupEmployeeData();
        })
        // const [startDate, setStartDate] = useState(new Date());
    }

    setupEmployees = () => {
        axios.post("/get_employees").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({employeesList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({employeesList:data});
            }
        })
    }
    setupCats = async() => {
        await axios.post("/get_cats").then((ok)=>{
            if(ok.data.status == "error") {
                this.setState({itemsCats:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                // data.push(<option value="">{"<Make as Main Category>"}</option>);
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                this.setState({itemsCats:data});
            }
            // console.log(ok.data);
        })
    }
    getDayDate = (dayNum) => {
        const date = new Date();
        date.setDate(date.getDate() + dayNum);
        const cYear = date.getFullYear();
        const cMonth = String(date.getMonth() + 1).length === 1 ? "0"+String(date.getMonth() + 1) : String(date.getMonth() + 1);
        const cDay = String(date.getDate()).length === 1 ? "0"+String(date.getDate()) : String(date.getDate());
        const dayDate = cYear+"-"+cMonth+"-"+cDay;
        return dayDate;
    }
    getMonthDate = (monthNum) => {
        const date = new Date();
        date.setDate(1);
        date.setMonth(date.getMonth() + monthNum);
        const fYear = date.getFullYear();
        const mMonth = String(date.getMonth() + 1).length === 1 ? "0"+String(date.getMonth() + 1) : String(date.getMonth() + 1);
        const monthDate = fYear+"-"+mMonth;
        return monthDate;
    }
    getYearDate = (yearNum) => {
        const date = new Date();
        date.setDate(1);
        date.setFullYear(date.getFullYear() + yearNum);
        const fYear = date.getFullYear();
        return fYear;
    }

    setStateSynchronous = (stateUpdate) => {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }

    setupBranches = async() => {
        window.toastr["info"]("Loading data...");
        await axios.post("/get_branches").then(async(ok)=>{
            if(ok.data.status == "error") {
                this.setState({branchesList:[
                    <option value="">{ok.data.msg}</option>
                ]});
            } else {
                var data = [];
                for(var i = 0; i < ok.data.msg.length; ++i) {
                    data.push(<option value={ok.data.msg[i]}>{ok.data.msg[i]}</option>);
                }
                await this.setStateSynchronous({branchesList:data});
            }
        })
        window.toastr.remove();
    }

    addAdmin = () => {
        const username = $("#usernameInput").val();
        const password = $("#passwordInput").val();
        const type = $("#adminType").val();
        const accessData = document.querySelectorAll(".queryAccess");
        var branch = $("#branchIn").val();
        var salary = $("#salaryIn").val();
        var workHours = $("#workHoursIn").val();
        var workHoursFromTo = $("#workHoursFromToIn").val();
        var offDaysNum = $("#offDaysIn").val();
        var offDaysName = $("#selectDaysBtn").text();

        var accessRes = "";
        for(var i = 0; i < accessData.length; ++i) {
            const isChecked = accessData[i].checked;
            if(isChecked) {
                accessRes += accessData[i].id;
            }
        }
        if(username.length < 3) {
            window.toastr["error"]("Invalid Username");
            return;
        }
        if(password.length < 3) {
            window.toastr["error"]("Invalid Password");
            return;
        }
        if(!accessRes) {
            window.toastr["error"]("Please select Access");
            return;
        }
        if(type === "employee") {
            if(!branch) {
                window.toastr["error"]("Invalid Branch");
                return;
            }
            if(!salary) {
                window.toastr["error"]("Invalid Salary");
                return;
            }
            if(!workHours) {
                window.toastr["error"]("Invalid Work Hours Number");
                return;
            }
            if(!workHoursFromTo) {
                window.toastr["error"]("Invalid Work Hours (From/To)");
                return;
            }

        } else {
            branch = "";
            salary = "";
            workHours = "";
            workHoursFromTo = "";
            offDaysNum = "";
            offDaysName = "";
        }

        axios.post("/add_admin", {username:username, password:password, access:accessRes, type:type, salary:salary, workHours:workHours, workHoursFromTo:workHoursFromTo, offDaysNum:offDaysNum, offDaysName:offDaysName, branch:branch}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addAdminModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    deleteAdmin = () => {
        axios.post("/del_admin", {id:this.state.adminIdToDel}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#deleteAdminModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    processDays = () => {
        const days = document.querySelectorAll(".queryAccessDays");
        var selectedDays = "";
        var count = 0;
        for(var i = 0; i < days.length; ++i) {
            const isChecked = days[i].checked;
            if(isChecked) {
                selectedDays += days[i].id+", ";
                count += 1;
            }
        }
        $("#offDaysIn").val(count);
        selectedDays = selectedDays.slice(0, -2);
        if(selectedDays) {
            $("#selectDaysBtn").text(selectedDays);
        } else {
            $("#selectDaysBtn").text("No OFF");
        }
        // console.log(selectedDays);
    }

    processTimeSum = () => {
        const data = $("#workHoursFromToIn").val();
        // console.log("["+data+"]");
        if(data && (data.match(/.*AM --- .*PM/) || data.match(/.*AM --- .*AM/) || data.match(/.*PM --- .*PM/) || data.match(/.*PM --- .*AM/))) {
            var firstNum = data.split(" --- ")[0].replace("A", "").replace("P", "").replace("M", "").replace(/ /g, "");
            var secondNum = data.split(" --- ")[1].replace("A", "").replace("P", "").replace("M", "").replace(/ /g, "");
            const firstPart = data.split(" --- ")[0];
            const secondPart = data.split(" --- ")[1];
            var numOfHours = "0";

            if(firstPart.includes("PM")) {
                if(!firstPart.includes("12")) {
                    firstNum = String(parseFloat(firstNum) + 12);
                }
            }
            if(secondPart.includes("PM")) {
                if(!secondPart.includes("12")) {
                    secondNum = String(parseFloat(secondNum) + 12);
                }
            }
            if(firstPart.includes("12AM")) {
                firstNum = "00";
            }
            if(secondPart.includes("12AM")) {
                secondNum = "00";
            }
            const timeStart = new Date("01/01/2007 " + firstNum+":00:00").getHours();
            const timeEnd = new Date("01/01/2007 " + secondNum+":00:00").getHours();
            numOfHours = timeEnd - timeStart;
            if (numOfHours < 0) {
                numOfHours = 24 + numOfHours;
            }
            // console.log("{"+numOfHours+"}");
            $("#workHoursIn").val(numOfHours);
            // console.log("--"+$("#workHoursIn").val()+"--");
        } else {
            window.toastr["error"]("Invalid Time Syntax, Accepted only (*AM --- *PM)");
            return false;
        }
        return true;
    }

    editAdmin = () => {
        const type = $("#adminType").val();
        if(type === "employee") {
            this.processDays();
            if(!this.processTimeSum()) {
                return;
            }
        }
        const password = $("#passwordInput").val();
        const accessData = document.querySelectorAll(".queryAccess")
        const status = $("#adminUpdateStatus").val();
        var branch = $("#branchIn").val();
        var salary = $("#salaryIn").val();
        var workHours = $("#workHoursIn").val();
        var workHoursFromTo = $("#workHoursFromToIn").val();
        var offDaysNum = $("#offDaysIn").val();
        var offDaysName = $("#selectDaysBtn").text();
        // console.log("("+workHours+")");

        var accessRes = "";
        for(var i = 0; i < accessData.length; ++i) {
            const isChecked = accessData[i].checked;
            if(isChecked) {
                accessRes += accessData[i].id;
            }
        }
        // if(password.length < 3) {
        //     window.toastr["error"]("Invalid Password");
        //     return;
        // }
        if(!accessRes) {
            window.toastr["error"]("Please select Access");
            return;
        }

        if(type === "employee") {
            if(!branch) {
                window.toastr["error"]("Invalid Branch");
                return;
            }
            if(!salary) {
                window.toastr["error"]("Invalid Salary");
                return;
            }
            if(!workHours) {
                window.toastr["error"]("Invalid Work Hours Number");
                return;
            }
            if(!workHoursFromTo) {
                window.toastr["error"]("Invalid Work Hours (From/To)");
                return;
            }
        } else {
            branch = "";
            salary = "";
            workHours = "";
            workHoursFromTo = "";
            offDaysNum = "";
            offDaysName = "";
        }

        axios.post("/edit_admin", {id:this.state.adminIdToDel, password:password, access:accessRes, status:status, type:type, salary:salary, workHours:workHours, workHoursFromTo:workHoursFromTo, offDaysNum:offDaysNum, offDaysName:offDaysName, branch:branch}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addAdminModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    logoutStaff = () => {
        const id = this.state.adminIdToDel;
        axios.post("/admin_logout_staff", {id:id}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#deleteAdminModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        });
    }

    setupEmployeeData = () => {
        const staff = $("#selectedEmployee").val();
        const date = $("#selectedDate").val();
        if(staff && date) {
            axios.post("/get_final_staff_data", {staff:staff, date:date}).then((ok)=>{
                if(ok.data.status === "success") {
                    $("#salaryInput").html(ok.data.salary);
                    $("#earnsInput").html(ok.data.earns);
                    $("#passedDaysInput").html(ok.data.count);
                    $("#finalEmployeeData").show();
                } else if(ok.data.status === "error") {
                    console.log(ok.data.msg);
                }
            });
        } else {
            $("#finalEmployeeData").hide();
        }
    }

    processEarnsClicked = async(node) => {
        const id = "#"+node.target.id;
        const wasActive = $(id).attr("isActive") === "yes";
        $(".reportsBtnRmBg").css({backgroundColor: ""});
        $(".reportsBtnRmBg").attr("isActive", "no");
        $("#earnsReportsView").hide();
        $("#totalSalesCountView").hide();
        $("#dateTableView").hide();
        $(".dateTableTr").hide();
        $(".dateTableTr2").hide();
        $(".customDatePicker").hide();
        $("#stockReportsTable_wrapper").hide();
        $("#salesReportsTable_wrapper").hide();
        $("#monthSellsByDayTable_wrapper").hide();
        $("#stockReportsView").hide();
        $("#categoryTableTr").hide();
        $("#discountTableTr").hide();
        if(!wasActive) {
            $(id).css({backgroundColor: "blue"});
            $(id).attr("isActive", "yes");
            await this.setupBranches();
            await this.setupCats();
            this.processDateChanged();
        }
    }
    processDateChanged = () => {
        if($("#earnsReports").attr("isActive") === "yes") {
            $("#dateTableView").show();
            $(".dateTableTr").show();
            const date = $("#selectedDate").val();
            if(date === "custom") {
                $(".customDatePicker").show();
            }
            const branch = $("#selectedBranch").val();
            window.toastr["info"]("Loading Data...");
            const fromDate = $("#fromDatePicketId").val();
            const toDate = $("#toDatePicketId").val();
            axios.post("/get_earns_reports", {date:date, fromDate:fromDate, toDate:toDate, branch:branch}).then((ok)=>{
                window.toastr.remove();
                window.toastr[ok.data.status](ok.data.msg);
                if(ok.data.status === "success") {
                    $("#earnsReportsView").show();
                    $("#earnsSetInput").html(ok.data.allEarns);
                    $("#expensesSetInput").html(ok.data.allExpenses);
                    $("#finalEarnsSetInput").html(ok.data.finalEarns);
                    $("#invoicesCountSetInput").html(ok.data.invoicesNum);
                    $("#totalClientsBalanceSetInput").html(ok.data.totalClientsBalance);
                }
            })
        }
        if($("#stockReports").attr("isActive") === "yes") {
            $("#dateTableView").show();
            $("#stockReportsTable_wrapper").show();
            $("#stockReportsTable").show();
            $("#stockReportsView").show();
            $("#categoryTableTr").show();
            window.$('#stockReportsTable').DataTable().ajax.reload(null, false);
        }
        if($("#salesReports").attr("isActive") === "yes") {
            $("#totalSalesCountView").show();
            $("#dateTableView").show();
            $(".dateTableTr").show();
            const date = $("#selectedDate").val();
            if(date === "custom") {
                $(".customDatePicker").show();
            }
            // $(".customDatePicker").show();
            $("#salesReportsTable_wrapper").show();
            $("#salesReportsTable").show();
            $("#categoryTableTr").show();
            window.$('#salesReportsTable').DataTable().ajax.reload(null, false);
        }
        this.processDateChanged2();
    }
    processDateChanged2 = () => {
        if($("#monthSellsByDay").attr("isActive") === "yes") {
            $("#dateTableView").show();
            $(".dateTableTr2").show();
            $("#monthSellsByDayTable_wrapper").show();
            $("#monthSellsByDayTable").show();
            $("#discountTableTr").show();
            // $("#stockReportsView").show();
            // $("#categoryTableTr").show();
            window.$('#monthSellsByDayTable').DataTable().ajax.reload(null, false);
        }
    }

    render() {
        // var ValuePiece = Date | null;
        // var Value = ValuePiece | [ValuePiece, ValuePiece];
        // const [value, onChange] = useState<Date>(new Date());
        return(
            <>
            
            <div class="modal fade" id="suppliersLogsHistory" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg" style={{maxWidth: "95%"}}>
                    <div class="modal-content" style={{backgroundColor: "gainsboro"}}>
                        <div class="modal-header">
                            Logs History of Staff ({this.state.adminToDel})
                        </div>
                        <div class="modal-body">
                            <input id="boxForSupplierId" style={{display: "none"}} />
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "left"}} onClick={() => {
                                window.$('#table2').DataTable().ajax.reload(null, false);
                            }}>Refresh</button>
                            <br/>
                            <br/>
                            <table class="table table-report dt-responsive -mt-2" id="table2" style={{width: "100%"}}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Staff ID</th>
                                        <th>Staff Name</th>
                                        <th>Login Date</th>
                                        <th>Status</th>
                                        <th>Logout Date</th>
                                        <th>Hours Passed</th>
                                        <th>Salary</th>
                                        <th>Working Hours</th>
                                        <th>Branch</th>
                                        <th>OFF Days</th>
                                        <th>Earns</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="deleteAdminModal" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Logging Out Staff
                        </div>
                        <div class="modal-body">
                            Are you sure you want to Force Logout ({this.state.adminToDel}) ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.logoutStaff();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#deleteAdminModal").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Reports Page</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>
                <button class='myBtnStyle reportsBtnRmBg' id="earnsReports" tabIndex={0} type='button' style={{display: "inline"}} onClick={(node) => {
                    this.processEarnsClicked(node);
                }}>Earns Reports</button>
                &nbsp;
                <button class='myBtnStyle reportsBtnRmBg' id="stockReports" tabIndex={0} type='button' style={{display: "inline"}} onClick={(node) => {
                    this.processEarnsClicked(node);
                }}>Stock Reports</button>
                &nbsp;
                <button class='myBtnStyle reportsBtnRmBg' id="salesReports" tabIndex={0} type='button' style={{display: "inline"}} onClick={(node) => {
                    this.processEarnsClicked(node);
                }}>Sales Reports</button>
                &nbsp;
                <button class='myBtnStyle reportsBtnRmBg' id="monthSellsByDay" tabIndex={0} type='button' style={{display: "inline"}} onClick={(node) => {
                    this.processEarnsClicked(node);
                }}>Month Sells By Day</button>
                <br/>
                <br/>

                <table className="table-modal" id="dateTableView" style={{display: "none", width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        <tr className="dateTableTr">
                            <th>Date:</th>
                            <td>
                                <select id="selectedDate" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    $(".customDatePicker").hide();
                                    if($("#selectedDate").val() === "custom") {
                                        $(".customDatePicker").show();
                                    } else {
                                        this.processDateChanged();
                                    }
                                }}>
                                    <option value="">All</option>
                                    <option value={this.getMonthDate(0)}>This Month {this.getMonthDate(0)}</option>
                                    <option value={this.getMonthDate(-1)}>Previous Month {this.getMonthDate(-1)}</option>
                                    <option value={this.getDayDate(0)}>This Day {this.getDayDate(0)}</option>
                                    <option value={this.getDayDate(-1)}>Previous Day {this.getDayDate(-1)}</option>
                                    <option value="custom">Custom Range</option>
                                </select>
                            </td>
                        </tr>
                        <tr className="dateTableTr2">
                            <th>Date:</th>
                            <td>
                                <select id="selectedDate2" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    this.processDateChanged();
                                }}>
                                    <option value={this.getMonthDate(0)}>{this.getMonthDate(0)}</option>
                                    <option value={this.getMonthDate(-1)}>{this.getMonthDate(-1)}</option>
                                    <option value={this.getMonthDate(-2)}>{this.getMonthDate(-2)}</option>
                                    <option value={this.getMonthDate(-3)}>{this.getMonthDate(-3)}</option>
                                    <option value={this.getMonthDate(-4)}>{this.getMonthDate(-4)}</option>
                                    <option value={this.getMonthDate(-5)}>{this.getMonthDate(-5)}</option>
                                    <option value={this.getMonthDate(-6)}>{this.getMonthDate(-6)}</option>
                                    <option value={this.getMonthDate(-7)}>{this.getMonthDate(-7)}</option>
                                    <option value={this.getMonthDate(-8)}>{this.getMonthDate(-8)}</option>
                                    <option value={this.getMonthDate(-9)}>{this.getMonthDate(-9)}</option>
                                    <option value={this.getMonthDate(-10)}>{this.getMonthDate(-10)}</option>
                                    <option value={this.getMonthDate(-11)}>{this.getMonthDate(-11)}</option>
                                    <option value={this.getMonthDate(-12)}>{this.getMonthDate(-12)}</option>
                                </select>
                            </td>
                        </tr>
                        <tr className="customDatePicker" style={{display: "none"}}>
                            <th>From Date:</th>
                            <td>
                                <DatePicker id="fromDatePicketId" selected={this.state.fromDateSet} showMonthDropdown dateFormat="yyyy-MM-dd" onChange={async(ee) => {
                                    await this.setStateSynchronous({fromDateSet:ee});
                                    this.processDateChanged();
                                }} />
                            </td>
                        </tr>
                        <tr className="customDatePicker" style={{display: "none"}}>
                            <th>To Date:</th>
                            <td>
                                <DatePicker id="toDatePicketId" selected={this.state.toDateSet} showMonthDropdown dateFormat="yyyy-MM-dd" onChange={async(ee) => {
                                    await this.setStateSynchronous({toDateSet:ee});
                                    this.processDateChanged();
                                }} />
                            </td>
                        </tr>
                        <tr>
                            <th>Branch:</th>
                            <td>
                                <select id="selectedBranch" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    this.processDateChanged();
                                }}>
                                    <option value="">All</option>
                                    {this.state.branchesList}
                                </select>
                            </td>
                        </tr>
                        <tr id="categoryTableTr">
                            <th>Category:</th>
                            <td>
                                <select id="selectedCategory" className="myInputStyle" style={{width: "fit-content"}} onChange={() => {
                                    this.processDateChanged();
                                }}>
                                    <option value="">All</option>
                                    {this.state.itemsCats}
                                </select>
                            </td>
                        </tr>
                        <tr id="discountTableTr">
                            <th>Discount:</th>
                            <td>
                                <input class="myInputStyle" id="discountValueReportsId" type="number" style={{width: "100%"}} onChange={() => {
                                    this.processDateChanged();
                                }} />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                    this.processDateChanged();
                }}>Refresh</button>
                <br/>
                <br/>
                
                <table className="table-modal" id="totalSalesCountView" style={{display: "none", width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        <tr>
                            <th>Total Count:</th>
                            <td id="totalSalesCountSet"></td>
                        </tr>
                    </tbody>
                </table>

                <table className="table-modal" id="earnsReportsView" style={{display: "none", width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        <tr>
                            <th>Earns:</th>
                            <td id="earnsSetInput"></td>
                        </tr>
                        <tr>
                            <th>Expenses:</th>
                            <td id="expensesSetInput"></td>
                        </tr>
                        <tr>
                            <th>Final Earns:</th>
                            <td id="finalEarnsSetInput"></td>
                        </tr>
                        <tr>
                            <th>Invoices Count:</th>
                            <td id="invoicesCountSetInput"></td>
                        </tr>
                        <tr>
                            <th>Total Suppliers/Clients Balance:</th>
                            <td id="totalClientsBalanceSetInput"></td>
                        </tr>
                    </tbody>
                </table>

                <table className="table-modal" id="stockReportsView" style={{display: "none", width: "fit-content", textAlignLast: "start", backgroundColor: "silver"}}>
                    <tbody>
                        <tr>
                            <th>Total Stock Count:</th>
                            <td id="stockCountSetInput"></td>
                        </tr>
                        <tr>
                            <th>Total Stock Cash:</th>
                            <td id="stockCashSetInput"></td>
                        </tr>
                    </tbody>
                </table>
                <br/>

                <table class="table table-report dt-responsive -mt-2" id="monthSellsByDayTable" style={{width: "100%", display: "none"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Date</th>
                            <th>Day</th>
                            <th>Total Sales Value</th>
                            <th>Total Profit Value</th>
                            <th>Branch</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-report dt-responsive -mt-2" id="stockReportsTable" style={{width: "100%", display: "none"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Qty</th>
                            <th>Warning Qty</th>
                            <th>Branch</th>
                            <th>Category</th>
                            <th>Cost</th>
                            <th>Total Cost</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-report dt-responsive -mt-2" id="salesReportsTable" style={{width: "100%", display: "none", fontSize: "smaller"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th style={{textAlign: "start"}}>Item</th>
                            <th>Sold Qty</th>
                            <th>Qty After Sell</th>
                            <th>Branch</th>
                            <th>Cost</th>
                            <th>Price</th>
                            <th>Discount</th>
                            <th>Final Price</th>
                            <th>Total Price</th>
                            <th>Profit</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Customer</th>
                            <th>Category</th>
                            <th>Invoice ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>
        )
    }
}

export default main;
