import React, { Component } from "react";
import { Route } from "react-router-dom";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import $ from "jquery";
import staff from "./staff";
import items from "./items";
import categories from "./categories";
import branches from "./branches";
import selling from "./selling";
import suppliers from "./suppliers";
import cashbox from "./cashbox";
import invoices from "./invoices";
import moneyOperations from "./money_operations";
import staffLogin from "./staff_login_panel";
import expenses from "./expenses";
import reports from "./reports";
import history from "./history";
import compareItems from "./compare_items";
import moveStockReqs from "./move_stock_reqs";
import serialsCodes from "./serials";
import servicePage from "./service_page";


class main extends Component {
    state = {
        user: [],
        entries: [],
        cashboxData: []
    }
    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            this.setupSelect2()
            // console.log("on route change");
        });
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            if(!ok.data.access.includes("|1|")) {
                this.props.history.push("/login");
                return;
            }
            this.setupEntries(ok);
            this.setupCashbox();
            this.setupRate();
        })
    }
    componentWillUnmount() {
        this.unlisten();
    }
    setupSelect2() {
        const interv4 = setInterval(function() {
            try {
                window.$("select").select2({width: '500px', dropdownAutoWidth: true, allowClear: false});
                window.$('select').off("select2:select");
                window.$('select').on("select2:select", function(e) {
                    // console.log("Firing: "+e.target.id+" => "+$("#"+e.target.id).val());
                    document.getElementById(e.target.id).dispatchEvent(new Event('change', {bubbles: true}));
                });
                clearInterval(interv4);
            } catch (ee) {
            }
        }, 1);
    }
    componentDidMount() {
        const self = this;
        this.setupSelect2();
        window.$(document).off("shown.bs.modal", ".modal");
        window.$(document).on("shown.bs.modal", ".modal", function () {
            self.setupSelect2();
            // console.log("modal opened");
        });
    }
    setupEntries = (ok) => {
        var panelEntries = [];
        if(ok.data.access.includes("|11|")) {
            panelEntries.push([
                <a id="selling_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/selling");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Selling</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|40|")) {
            panelEntries.push([
                <a id="moneyOperations_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/money-operations");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Money Operations</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|28|")) {
            panelEntries.push([
                <a id="cashbox_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/cashboxes");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Cashboxes</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|32|")) {
            panelEntries.push([
                <a id="invoices_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/invoices");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Invoices</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|17|")) {
            panelEntries.push([
                <a id="branches_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/branches");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Branches</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|2|")) {
            panelEntries.push([
                <a id="admins_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/staff");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Staff</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|45|")) {
            panelEntries.push([
                <a id="staff-login_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/staff-login");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Staff Login History</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|22|")) {
            panelEntries.push([
                <a id="suppliers_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/suppliers");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Suppliers / Clients</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|6|")) {
            panelEntries.push([
                <a id="items_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/items");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Items</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|72|")) {
            panelEntries.push([
                <a id="service_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/service");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Service</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|68|")) {
            panelEntries.push([
                <a id="serials_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/serials");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Serials Codes</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|16|")) {
            panelEntries.push([
                <a id="move-stock-reqs_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/move-stock-requests");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Move Stock Requests</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|59|")) {
            panelEntries.push([
                <a id="compare-items_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/compare-items");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Compare Items</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|12|")) {
            panelEntries.push([
                <a id="cats_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/categories");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Categories</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|48|")) {
            panelEntries.push([
                <a id="expenses_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/expenses");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Expenses</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|47|")) {
            panelEntries.push([
                <a id="reports_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/reports");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Reports</span></div>
                </a>
            ]);
        }
        if(ok.data.access.includes("|52|")) {
            panelEntries.push([
                <a id="history_btn" tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                    this.props.history.push("/panel/history");
                }}>
                    <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                    </svg>
                    </svg>
                    <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">History</span></div>
                </a>
            ]);
        }

        panelEntries.push([
            <a tabIndex={0} itempadding={16} role="button" className="MuiButtonBase-root MuiListItem-root MuiListItem-gutters MuiListItem-padding MuiListItem-button fuse-list-item muiltr-fkgub2-MuiButtonBase-root-MuiListItem-root" onClick={() => {
                axios.post("/req_logout").then((ok)=>{
                    window.toastr[ok.data.status](ok.data.msg);
                    if(ok.data.status === "success") {
                        this.props.history.push("/login");
                    }
                })
            }}>
                <svg className="shrink-0 fill-current  fuse-list-item-icon shrink-0 MuiBox-root muiltr-syvc0x" size={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <svg id="view-boards" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                </svg>
                </svg>
                <div className="MuiListItemText-root fuse-list-item-text muiltr-tlelie-MuiListItemText-root"><span className="MuiTypography-root MuiTypography-body1 MuiListItemText-primary text-13 font-medium fuse-list-item-text-primary truncate muiltr-y31q5n-MuiTypography-root">Logout</span></div>
            </a>
        ]);
        this.setState({entries:panelEntries});
        $(".MuiButtonBase-root").css({backgroundColor: ""});
        $("#coverData").css({"z-index": ""});
    }

    setupCashbox = () => {
        axios.post("/get_cashbox").then((ok2)=>{
            $("#cashboxStatus").hide();
            $("#cashboxUsdAmount").hide();
            $("#cashboxLbpAmount").hide();
            $("#cashboxNote").hide();
            $("#openCloseCashBoxBtnId").hide();
            $("#openCloseCashBoxBtnId").css({color: "black"});
            if(ok2.data.status === "error") {
                $("#cashboxStatus").show();
                $("#cashBoxStatusVal").html(ok2.data.msg);
            }
            if(ok2.data.status === "zero") {
                $("#cashboxStatus").show();
                $("#cashBoxStatusVal").html(ok2.data.msg);
                $("#openCloseCashBoxBtnId").show();
                $("#openCloseCashBoxBtnId").html("Open Cashbox");
            }
            if(ok2.data.status === "open") {
                $("#cashboxStatus").show();
                $("#cashBoxStatusVal").html("Opened");
                $("#cashBoxStatusVal").css({color: "green"});

                $("#cashboxUsdAmount").show();
                $("#cashBoxUsdVal").html(parseFloat(ok2.data.usd).toLocaleString("en", {useGrouping:true}));
                $("#cashboxLbpAmount").show();
                $("#cashBoxLbpVal").html(parseFloat(ok2.data.lbp).toLocaleString("en", {useGrouping:true}));

                $("#openCloseCashBoxBtnId").show();
                $("#openCloseCashBoxBtnId").css({color: "firebrick"});
                $("#openCloseCashBoxBtnId").html("Close Cashbox");
            }
            if(ok2.data.status === "close") {
                $("#cashboxStatus").show();
                $("#cashBoxStatusVal").html("Closed");
                $("#cashBoxStatusVal").css({color: "red"});

                $("#cashboxUsdAmount").show();
                $("#cashBoxUsdVal").html(parseFloat(ok2.data.usd).toLocaleString("en", {useGrouping:true}));
                $("#cashboxLbpAmount").show();
                $("#cashBoxLbpVal").html(parseFloat(ok2.data.lbp).toLocaleString("en", {useGrouping:true}));

                $("#openCloseCashBoxBtnId").show();
                $("#openCloseCashBoxBtnId").html("Open Cashbox");
            }
        })
    }

    setupRate = () => {
        axios.post("/get_rate").then((ok)=>{
            if(ok.data) {
                this.setState({rate:ok.data});
            }
        })
    }

    processCashbox = () => {
        const cmd = $("#openCloseCashBoxBtnId").html();
        if(cmd === "Open Cashbox") {
            window.$("#addBranchModalx2").modal("show");
        } else if(cmd === "Close Cashbox") {
            // window.toastr["error"]("Close Cashbox is not allowed");
            axios.post("/close_cashbox").then((ok)=>{
                window.toastr[ok.data.status](ok.data.msg);
                if(ok.data.status === "success") {
                    this.setupCashbox();
                }
            })
        }
    }
    doOpenCashbox = () => {
        const inUsdAmount = $("#inUsdAmount").val();
        const inLbpAmount = $("#inLbpAmount").val();
        const note = $("#inAmount").val();
        if(!inUsdAmount || parseFloat(inUsdAmount) < 0) {
            window.toastr["error"]("Invalid In USD Amount");
            return;
        }
        if(!inLbpAmount || parseFloat(inLbpAmount) < 0) {
            window.toastr["error"]("Invalid In LBP Amount");
            return;
        }
        axios.post("/open_cashbox", {in_usd_amount:inUsdAmount, in_lbp_amount:inLbpAmount, note:note}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addBranchModalx2").modal("hide");
                this.setupCashbox();
            }
        })
    }
    setupRate = () => {
        axios.post("/get_rate").then((ok)=>{
            if(ok.data) {
                this.setState({rate:ok.data});
            }
        })
    }

    render() {



        return(
            <div id="fuse-layout" className="w-full flex muiltr-b10jnn">
                <div className="flex flex-auto min-w-0">
                <div style={{maxWidth: "15%", minWidth: "225px"}} className="flex-col flex-auto sticky top-0 overflow-hidden h-screen shrink-0 z-20 shadow-5 muiltr-1fehr9c" open>
                    <div className="flex flex-auto flex-col overflow-hidden h-full muiltr-1br9tiy">
                    <div className="flex flex-1 flex-col min-h-0 muiltr-mh0qts ps ps--active-y" style={{position: 'relative'}}>
                        <div className="user relative flex flex-col items-center justify-center p-16 pb-14 shadow-0 muiltr-rfuhqd" style={{alignItems: "start", paddingLeft: "5px", paddingRight: "5px", paddingTop: "5px"}}>
                            <h1 id="company" tabIndex={0} style={{width: "100%", textAlign: "center", margin: "5px 0px 10px 0px"}}>EasyTech</h1>
                            <table className="table-modal" style={{fontSize: "small", width: "100%", textAlignLast: "start", backgroundColor: "silver", color: "black", marginBottom: "10px"}}>
                                <tbody>
                                    <tr>
                                        <th>{this.state.user.type}:</th>
                                        <td>{this.state.user.username}</td>
                                    </tr>
                                    <tr>
                                        <th>Branch:</th>
                                        <td>{!this.state.user.branch && "All" || this.state.user.branch}</td>
                                    </tr>
                                    <tr id="cashboxStatus">
                                        <th>Cashbox:</th>
                                        <td id="cashBoxStatusVal"></td>
                                    </tr>
                                    <tr id="cashboxUsdAmount">
                                        <th>USD:</th>
                                        <td id="cashBoxUsdVal"></td>
                                    </tr>
                                    <tr id="cashboxLbpAmount">
                                        <th>LBP:</th>
                                        <td id="cashBoxLbpVal"></td>
                                    </tr>
                                    <tr id="cashboxNote">
                                        <th>Note:</th>
                                        <td id="cashBoxNoteVal"></td>
                                    </tr>
                                    <tr>
                                        <th>Rate:</th>
                                        <td style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                            {parseFloat(this.state.rate).toLocaleString("en", {useGrouping:true})}
                                            &nbsp;
                                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", borderRadius: "0px", height: "25px", width: "fit-content"}} onClick={() => {
                                                this.setupRate();
                                            }}>Refresh</button>
                                        </td>
                                    </tr>
                                    {/* {this.state.cashboxData} */}
                                </tbody>
                            </table>
                            <button id="openCloseCashBoxBtnId" class='myBtnStyle' tabIndex={0} type='button' style={{width: "100%"}} onClick={() => {
                                this.processCashbox();
                            }}></button>
                        </div>
                        <ul className="MuiList-root MuiList-padding navigation whitespace-nowrap px-12 py-0 active-undefined-list navigation muiltr-n5tsn5-MuiList-root">
                        
                        {this.state.entries}
                        
                        </ul>
                        <div className="flex flex-0 items-center justify-center py-48 opacity-10"><img className="w-full max-w-64" src="/assets/style/logo.svg" alt="footer logo" /></div>
                    </div>
                    </div>
                </div>
                <main id="fuse-main" className="flex flex-col flex-auto min-h-full min-w-0 relative">
                    <div className="sticky top-0 z-99" />
                    <div className="flex flex-col flex-auto min-h-0 relative">
                        <div className="FusePageCarded-root FusePageCarded-scroll-content muiltr-zjekyz" style={{marginBottom: "100px"}}>

                            <div class="modal fade" id="addBranchModalx2" role="dialog" tabindex="-1">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-body">
                                            <table className="table-modal" style={{width: "100%"}}>
                                                <tbody>
                                                    <tr>
                                                        <th>In USD Amount:</th>
                                                        <td><input class="myInputStyle" type="number" id="inUsdAmount"/></td>
                                                    </tr>
                                                    <tr>
                                                        <th>In LBP Amount:</th>
                                                        <td><input class="myInputStyle" type="number" id="inLbpAmount"/></td>
                                                    </tr>
                                                    <tr>
                                                        <th>Note:</th>
                                                        <td><input class="myInputStyle" id="inAmount"/></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="modal-footer">
                                            <button class='myBtnStyle' id='openCashboxBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                                this.doOpenCashbox();
                                            }}>Open</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Route exact path="/panel/staff" component={staff} />
                            <Route exact path="/panel/items" component={items} />
                            <Route exact path="/panel/compare-items" component={compareItems} />
                            <Route exact path="/panel/categories" component={categories} />
                            <Route exact path="/panel/branches" component={branches} />
                            <Route exact path="/panel/selling" component={selling} />
                            <Route exact path="/panel/suppliers" component={suppliers} />
                            <Route exact path="/panel/cashboxes" component={cashbox} />
                            <Route exact path="/panel/invoices" component={invoices} />
                            <Route exact path="/panel/money-operations" component={moneyOperations} />
                            <Route exact path="/panel/staff-login" component={staffLogin} />
                            <Route exact path="/panel/expenses" component={expenses} />
                            <Route exact path="/panel/reports" component={reports} />
                            <Route exact path="/panel/history" component={history} />
                            <Route exact path="/panel/move-stock-requests" component={moveStockReqs} />
                            <Route exact path="/panel/serials" component={serialsCodes} />
                            <Route exact path="/panel/service" component={servicePage} />

                        </div>
                    </div>
                </main>
                </div>
            </div>
        )
    }
}

export default main;
